<template>
  <div class="">
      <div class="content content-fixed bd-b">
        <div :class="`container pd-x-0 pd-lg-x-10 pd-xl-x-0 ${getScreenWidth()<=992?'mg-t-60':''}`">
            <div class="d-sm-flex align-items-center justify-content-between">
            <div>
                <h4 class="mg-b-5">Дараалалд бүртгүүлэх</h4>
                <p class="mg-b-0 tx-color-03">хүсэлт илгээгдсэн огноогоор дарааллын код олгогдоно</p>
            </div>
            <div class="mg-t-20 mg-sm-t-0">
                <router-link :to="{name: 'list', params: {id: 0, p1: '0'} }" role="button" class="btn btn-primary"> Дарааллын жагсаалт харах</router-link>
            </div>
            </div>
        </div><!-- container -->
      </div>

      <div class="content tx-13">
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="text-center wd-100p">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label text-right">Овог</label>
                            <div class="col-sm-8">
                                <input v-model="postModel.lastName" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label text-right">Нэр</label>
                            <div class="col-sm-8">
                                <input v-model="postModel.firstName" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label text-right">Регистрийн дугаар</label>
                            <div class="col-sm-8">
                                <input  v-model="postModel.regNo" maxlength="10" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label text-right">Утасны дугаар</label>
                            <div class="col-sm-8">
                                <input v-model="postModel.mobile" :maxlength="8" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label text-right">Жолоочийн гэрийн хаяг</label>
                            <div class="col-sm-8">
                                <textarea v-model="postModel.address" type="text" class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Тээврийн хэрэгслийн дугаар, сери</label>
                        <div class="col-sm-8">
                            <input v-model="postModel.plateNo" maxlength="7" type="text" class="form-control">
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Тээврийн хэрэгслийн сери</label>
                        <div class="col-sm-8">
                            <input v-model="postModel.model" type="text" class="form-control">
                        </div>
                    </div> -->
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Тээврийн хэрэгслийн марк</label>
                        <div class="col-sm-8">
                            <input v-model="postModel.mark" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Тээврийн хэрэгслийн арлын дугаар</label>
                        <div class="col-sm-8">
                            <input v-model="postModel.vin" type="text" class="form-control">
                        </div>
                    </div>
                    <hr />
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Жолооны үнэмлэх</label>
                        <div class="col-sm-8">
                            <simple-uploader @on-success="defaultSuccess" class="wd-100p">
                                <template v-slot:trigger>
                                    <div v-if="postModel.defaultAttachmentId==null" class="btn btn-outline-warning btn-block">сонгох</div>
                                    <div @click.stop="postModel.defaultAttachmentId=null" v-else class="btn btn-outline-danger btn-block">файл устгах</div>
                                </template>
                            </simple-uploader>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Тээврийн хэрэгслийн гэрчилгээ</label>
                        <div class="col-sm-8">
                            <simple-uploader @on-success="secondSuccess" class="wd-100p">
                                <template v-slot:trigger>
                                    <div v-if="postModel.secondAttachmentId==null" class="btn btn-outline-warning btn-block">сонгох</div>
                                    <div @click.stop="postModel.secondAttachmentId=null" v-else class="btn btn-outline-danger btn-block">файл устгах</div>
                                </template>
                            </simple-uploader>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label text-right">Вакцины гэрчилгээ</label>
                        <div class="col-sm-8">
                            <simple-uploader @on-success="thirdSuccess" class="wd-100p">
                                <template v-slot:trigger>
                                    <div v-if="postModel.thirdAttachmentId==null" class="btn btn-outline-warning btn-block">сонгох</div>
                                    <div @click.stop="postModel.thirdAttachmentId=null" v-else class="btn btn-outline-danger btn-block">файл устгах</div>
                                </template>
                            </simple-uploader>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            

            <div class="row justify-content-between">
            <div class="col-sm-6 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <!-- <label class="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">Санамж</label>
                <p class="text-warning">Дээрхи мэдээллийг үнэн зөв бөглөнө үү. Алдаатай мэдээлэл оруулсан тохиолдолд бид хариуцахгүй болно</p> -->
                <div class="alert alert-warning mg-b-0" role="alert">
                    <h6 class="alert-heading">Санамж !</h6>
                    <p class="mg-b-0">Дээрхи мэдээллийг үнэн зөв бөглөнө үү. Алдаатай мэдээлэл оруулсан тохиолдолд та хүсэлтээ цуцлан шинээр дараалалд бүртгүүлэх шаардлагатай болно.</p>
                </div>
            </div><!-- col -->
            <div class="col-sm-6 col-lg-4 order-1 order-sm-0">
                

                <button @click="resndReq()" class="btn btn-block btn-success">Хүсэлт илгээх</button>
            </div><!-- col -->
            </div><!-- row -->
        </div><!-- container -->
        </div>
  </div>
</template>

<script>
import { request } from '@/api/all';
import { mapActions } from "vuex";
import { getToken } from "@/libs/util";

import SimpleUploader from "@/components/simpleUploader.vue";

export default {
    components:{
      SimpleUploader
    },
    data(){
        return{
            postModel: {
                plateNo: '', 
                vin: '', 
                firstName: '', 
                lastName: '', 
                regNo: '', 
                mobile: '', 
                address: '', 
                model: '', 
                mark: '', 
                defaultAttachmentId: null, 
                secondAttachmentId: null, 
                thirdAttachmentId: null,
            }
        }
    },
    methods:{
        ...mapActions(["handleLoading"]),
        resndReq(){
            this.handleLoading({ isLoading: true });
            request({
                api: { method: 'post', url: 'tads/CreateTadsSequence'},
                data: this.postModel,
                store: this.$store,
                }).then(res => {
                this.handleLoading({ isLoading: false });
                if (res !== false) {
                    console.log(res);
                    if(res.data.result==1){
                        this.$router.push({name: 'list', params: { id: res.data.taskId, p1: res.data.pass }});
                        alert('Та дараах нууц кодыг тэмдэглэж авна уу. Энэхүү нууц кодыг ашиглан дарааллаа цуцлах хүсэлт илгээх тул нууцлалыг чандлан хадгална уу: '+res.data.pass);

                    }else{
                        this.$router.push({name: 'list', params: { id: res.data.taskId, p1: '0' }});
                        alert('Энэ улсын дугаараар дараалалд бүртгүүлсэн байна. Та дарааллаа шинэчлэх бол өнмөх хүсэлтээ цуцалж дахин шинэ хүсэлт илгээнэ үү');
                    }
                    //console.log(res);
                }
            });
        },
        defaultSuccess(e) {
            this.postModel.defaultAttachmentId= e.uploadedAttachmentId
        },
        secondSuccess(e) {
            this.postModel.secondAttachmentId= e.uploadedAttachmentId
        },
        thirdSuccess(e) {
            this.postModel.thirdAttachmentId= e.uploadedAttachmentId
        },
        getScreenWidth(){
            return screen.width;
        }
    }

    //'54605'
}
</script>

<style>

</style>